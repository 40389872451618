import React from "react";
import $ from "jquery";
import { ApiPostCall } from "../Functions/connector";

class ExtendRental extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ERisCancelled: false, erMonth: [], errors: {} };
  }

  ERconfirm2 = () => {
    let isValid = true;
    let errors = {};
    var edate = this.props.EDate.split("/");
    var dt = $("#EXtoCalender").val();
    console.log(dt.toString());
    if (dt) {
      var date = new Date(edate);
      var numberOfDaysToAdd = 30;
      var erdate = date.setDate(date.getDate() + numberOfDaysToAdd);
      var convertedEpoch = new Date(parseInt(erdate)).toLocaleString("en-US", {
        hour12: true,
      });
      var SplitDateTime = convertedEpoch.split(",");
      var addedDate = SplitDateTime[0].split("/");
      var adddateOld = dt.split("-");
      if (addedDate[0] < 10) {
        var addedDateM = 0 + addedDate[0];
      } else {
        addedDateM = addedDate[0];
      }
      if (addedDate[1] < 10) {
        var addedDateD = 0 + addedDate[1];
      } else {
        addedDateD = addedDate[1];
      }
      var adddateold = adddateOld[0] + adddateOld[1] + adddateOld[2];
      var adddatenew = addedDate[2] + addedDateM + addedDateD;
      if (adddateold > adddatenew) {
        this.setState({ ERisCancelled: false });
        errors["erdate"] =
          "For extending rentals over a month, please contact customer support.";
        this.setState({
          errors: errors,
        });
        isValid = false;
        return false;
      }
    }

    var ERmonth = $("#EXtoCalender").val();
    if (ERmonth == null || ERmonth == "") {
      this.setState({ ERisCancelled: false });
      errors["erdate"] = "Please select Date";
      this.setState({
        errors: errors,
      });
      isValid = false;
      return false;
    }

    var erMonths = ERmonth.split("-");
    if (ERmonth != null || (ERmonth != "" && isValid)) {
      this.setState({ erMonth: erMonths });
      isValid = true;
    }
    if (erMonths[0] < edate[2]) {
      this.setState({ ERisCancelled: false });
      errors["erdate"] = "New Date smaller or equal existing end date.";
      this.setState({
        errors: errors,
      });
      isValid = false;
      return false;
    }

    if (isValid) {
      const token = window.localStorage.getItem("Token");
      var urlencoded = new URLSearchParams();
      urlencoded.append("Date", dt.toString());
      urlencoded.append("RentalCode", this.props.RcoDe.toString());
      urlencoded.append("Token", token);
      ApiPostCall("/RouterParser/ExtendRental", urlencoded).then((result) => {
        // console.log("got it", result);
        var ifTrue = result.split(/[\s,\?\,\.!]+/).some((f) => f === "Error");
        if (ifTrue) {
          errors["erdate"] = "New Date smaller or equal existing end date.";
          this.setState({
            errors: errors,
          });
        } else {
          this.setState({ ERisCancelled: true });
        }
        // if (result === undefined || result === "") {
        //   alert("Something went wrong");
        // } else {
        //   const responseRs = JSON.parse(result);
        //   console.log(responseRs);
        // }
      });
    }
  };

  ERDismiss = () => {
    $(".deleteCardDismissBtn").find("#closebtn2").click();
  };

  render() {
    return (
      <>
        {!this.state.ERisCancelled && (
          <>
            <div className="ERfont1">Extend Rental</div>
            <div className="ERfont2 p-3" style={{
              textAlign:"left"
            }}>
<ul className="extend-rental">
  <li>Short-term rental: $3.99 + VAT/day, capped at 1 month.</li>
<li>Student rental: Extends with current monthly fee, until cancelled.</li>
<li>Other rentals: $6.99 + VAT/day, capped at 1 month.</li>

</ul>

              {/* Add up to a month at $3.99 + VAT per day. */}
            </div>
            <div className="p-2">
              <div className="ERfont3">To</div>
              <input
                className="ERfromCalender"
                name="begin Calender"
                type="date"
                id="EXtoCalender"
              // value={this.state.FromDate || ""}
              // onChange={this.handleChangeFromDate.bind(this)}
              />
              <div className="text-danger">{this.state.errors.erdate}</div>
            </div>
            {/* <div className="ERfont4 pt-3">You’ll be charged</div> */}
            {/* <div className="ERfont4 pt-3">$25.00</div> */}
            <div className="pt-4 pb-3">
              <button className="ERConBtn" onClick={this.ERconfirm2}>
                Confirm
              </button>
            </div>
          </>
        )}
        {this.state.ERisCancelled && (
          <>
            <div className="ERfont1">Rental Extended!</div>
            <div className="ERfont2 p-3">
              Thank you for choosing to extend your service with TalknSave. The
              new requested end date is:
            </div>
            <div className="ERfont4 pt-3">Until</div>
            <div className="ERfont4">{`${this.state.erMonth[1]}/${this.state.erMonth[2]}/${this.state.erMonth[0]}`}</div>
            <div className="successText pt-2 " text-center>
              This will reflect in few minutes.
            </div>
            <div className="pt-4 pb-3">
              <button className="ERConBtn" onClick={this.ERDismiss}>
                Confirm
              </button>
            </div>
          </>
        )}
      </>
    );
  }
}
export default ExtendRental;
