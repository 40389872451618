import React from "react";
import "./freezeSimModal.css";
import $ from "jquery";

class allDetailsSaved extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  close = () => {
    $(".popupsize").find("#closebtn2").click();
  };
  render() {
    return (
      <>
        <div className="details">
          <div className="text-center alldetailsFont">Sim Card Frozen!</div>
          <div className="text-center textFont">
            Your request has been sent, you will receive an Email for further
            information !
          </div>
        </div>
        <div className="row">
          {/* <div className="col-lg">
            <button
              type="submit"
              className="btn btn applybtn2"
              onClick={this.close}
            >
              <div className="applyFont">Close</div>
            </button>
          </div> */}
        </div>
      </>
    );
  }
}
export default allDetailsSaved;
