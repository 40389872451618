import React from "react";
import "./DataTable.css";
import $ from "jquery";
import { convertUnixToDateTime } from "../Helper/HelperFun";
class DataTableUsage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    var resultData = [];
    var OnceFeesLenght = Math.ceil(this.props.RNum.length);

    var ifZero = "MarginTop";

    if (this.props.RNum) {

console.log("this.props.RNum",this.props.RNum);
const sortedOnceFees = this.props.RNum
.sort((a, b) => {
    const aDate = parseInt(a.EnteredOn.match(/\d+/)[0], 10);
    const bDate = parseInt(b.EnteredOn.match(/\d+/)[0], 10);
    return bDate - aDate; // Descending order
});
      for (let i = 0; i < OnceFeesLenght; i++) {
if(sortedOnceFees[i].DebitFor>0)
{
        if (sortedOnceFees[i].EnteredOn) {
          var splitDateTime = convertUnixToDateTime(
            sortedOnceFees[i].EnteredOn
          ).split(",");
          // console.log(splitDateTime);
        }
        ifZero = "false";

        resultData.push(
          <>
            <tr key={i}>
              <td className="TableDisplayBlock">{i}</td>
              <td>{splitDateTime[0]}</td>
              <td>{sortedOnceFees[i].OnceFeeName}</td>
              <td>{`$${sortedOnceFees[i].DebitFor}`}</td>
            </tr>
          </>
        );
      }
      }
    }

    return (
      <>
        <div className={`${ifZero}`}>
          <table id="paginationNumbers" className="table" width="100%">
            <thead>
              <tr>
                <th className="th-sm TableDisplayBlock"></th>
                <th className="th-sm ">
                  Date<i className="fa fa-arrows-v hoverarrows"></i>
                </th>
                <th className="th-sm">
                Charge Details<i className="fa fa-arrows-v hoverarrows"></i>
                </th>
                {/* <th className="th-sm">
                  Addon<i className="fa fa-arrows-v hoverarrows"></i>
                </th> */}
                <th className="th-sm ">
                  Total<i className="fa fa-arrows-v hoverarrows"></i>
                </th>
              </tr>
            </thead>
            <tbody>{resultData}</tbody>
            {/* <tfoot>
                  <tr>
                    <th>Name</th>
                    <th>Position</th>
                    <th>Office</th>
                    <th>Age</th>
                    <th>Start date</th>
                    <th>Salary</th>
                  </tr>
                </tfoot> */}
          </table>
        </div>
      </>
    );
  }
}

export default DataTableUsage;
