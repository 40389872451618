import * as Yup from "yup";
import $ from "jquery";

const numRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const PersonalDetailsValidation = Yup.object().shape({
  firstName: Yup.string().required("Please enter your first Name."),
  lastname: Yup.string().required("Please enter your last Name."),
  email: Yup.string()
    .email("Please enter valid Email.")
    .required("Please enter your Email."),
  // C_email: Yup.string()
  //   .email("Please enter valid Email.")
  //   .oneOf([Yup.ref("email"), null]),
  // phone: Yup.string()
  //   .matches(numRegExp, "Phone number is not valid.")
  //   .max(10, "Please enter 10 digit phone number."),
  // address: Yup.string().required("Please enter your address."),
  // city: Yup.string().required("Please enter your city."),
  // country: Yup.string().required("Please enter your country."),
  // state: Yup.string().required("Please enter your state."),
  // zip: Yup.string()
  //   .matches(numRegExp, "Please enter your zip code.")
  //   .max(6, "Please enter 6 digit zip code."),
  // password: Yup.string().required("Please enter your password"),
});

export const ChangePasswordValidation = Yup.object().shape({
  password: Yup.string().required("Please enter your password"),
  npassword: Yup.string()
    .required("Please enter your new password.")
    .min(8, "Password should be between 8-16 characters!")
    .max(16, "Password should be between 8-16 characters!"),
  cpassword: Yup.string()
    .required("Please enter your confirm password.")
    .min(8, "Password should be between 8-16 characters!")
    .max(16, "Password should be between 8-16 characters!")
    .oneOf([Yup.ref("npassword"), null]),
});

export const AddNewCardValidation = Yup.object().shape({
  cardNum: Yup.number("Please enter only numbers.")
    .test(
      "len",
      "Must be exactly 16 characters",
      (val) => val && val.toString().length === 16
    )
    .required("Please enter your card number.")
    .test("validator-custom-name", "", (value) => {
      if (value.toString().length == 1 || value.toString().length == 15)
        $(".IncorrectCardNumber").text("");
      else return true;
    }),
  cardType: Yup.string().required("Please select year."),
  cardMonth: Yup.string().required("Please select month."),
  cardYear: Yup.string().required("Please select year."),
});

export const PayBillYupValidation = Yup.object().shape({
  cardNum: Yup.number("Please enter only numbers.")
    .test(
      "len",
      "Must be exactly 16 characters",
      (val) => val && val.toString().length === 16
    )
    .required("Please enter your card number."),
  cardName: Yup.string().required("Please enter your card name."),
  cardMonth: Yup.string().required("Please select month."),
  cardYear: Yup.string().required("Please select year.")
});

export const FreezeSimValidation = Yup.object().shape({
  lostSim: Yup.string().required("Please select Phone No."),
  nameShippingLocation: Yup.string().required("Please enter your name."),
  phoneNum: Yup.string().matches(/^\d+$/, "Please enter only digits.").test(
    "len",
    "Must be exactly 9 to 10 numbers only",
    (val) =>{ 
      console.log("val", val);
      console.log("toString", val.toString());
      console.log("length", val.toString().length);
      if (val && val.toString().length >= 9 && val.toString().length <= 10) return true;
    }
  ),
  address: Yup.string().required("Please enter your address."),
  floor: Yup.string().required("Please enter Entrance/Floor/Apt."),
  city: Yup.string().required("Please enter city."),
  shippingMethod: Yup.string().required("Please select Shipping Method."),
  comments: Yup.string(),
});
