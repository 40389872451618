import React from "react";
import "./topbar.css";


import AccountDetails from "../Account/ApiControll";
import PayBillFun from "../Bills/PayBillFun";
class Topbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      count: 0,
      profilePic: "/images/dummyUser.jpg",
      greet: "Good Morning",
      UserName: "",
      PageName: "",
      isWrongDialedOrBundle: false,
      user: "",
    };
  }
  componentDidMount() {
    const loggedInUser = window.localStorage.getItem("userData");
    const User = window.localStorage.getItem("user");
    const isWrongDialedOrBundle = window.localStorage.getItem(
      "isWrongDialedOrBundle"
    );
    this.setState({
      user: User,
    });
    if (isWrongDialedOrBundle == "false") {
      if (User != "Location Portal") {
        const foundUser = JSON.parse(loggedInUser);
        this.setState({
          ClientName: foundUser[0][0].ClientName,
          ClientEmail: foundUser[0][0].ClientEmail,
        });

        AccountDetails().then((result) => {
          if (result === undefined || result === "") {
            alert("Something went wrong");
          } else {
            const datas = JSON.parse(result);
            this.setState({
              profilePic:
                datas[0] != null && datas[0][0].ProfileImage
                  ? `//wpapi.talknsave.net/templateshtml/profileimages/${datas[0][0].ProfileImage}`
                  : "/images/dummyUser.jpg",
              UserName:
                datas[0][0].ClientFirstName + " " + datas[0][0].ClientLastName,
            });
          }
        });
      }
      var myDate = new Date();
      var hrs = myDate.getHours();

      // if (hrs < 12) this.setState({ greet: "Good Morning" });
      // else if (hrs >= 12 && hrs <= 17) this.setState({ greet: "Good Afternoon" });
      // else if (hrs >= 17 && hrs <= 24) this.setState({ greet: "Good Evening" });
      this.setState({
        greet:
          hrs < 12
            ? "Good Morning"
            : hrs >= 12 && hrs <= 17
              ? "Good Afternoon"
              : hrs >= 17 && hrs <= 24
                ? "Good Evening"
                : "",
      });
      this.setState({ isWrongDialedOrBundle: true });
    }

    var pageName = window.location.pathname.replace("/", "");
    pageName = pageName.charAt(0).toUpperCase() + pageName.slice(1);
    if (pageName == "Invoice") {
      pageName = "Invoices";
    } else if (pageName == "Account") {
      pageName = "My Account";
    } else if (pageName == "Payments") {
      pageName = "Payments Methods";
    } else if (pageName == "Assign-phone") {
      pageName = "Assign Number For New Orders";
    } else if (pageName == "Recent-orders") {
      pageName = "Recent Orders";
    } else if (pageName.split("/")[0] == "Place-order") {
      pageName = "Place an Order";
    } else if (pageName.split("/")[0] == "Wrong-dialed-calls") {
      pageName = "Wrong dialed calls";
    } else if (pageName.split("/")[0] == "Bundle-order") {
      pageName = "Bundle Order";
    }
    this.setState({ PageName: pageName });



  }




     
   

  
  render() {
    return (
      <div className="col-12 staticHeader">
        <div className="row">
          <div className="col-lg-2 col-7">
            <div className="mobileIcoLogo ">
              <div className="mobileHeaderLogo d-flex">
                <div className="HamMenuContainer" >
                  <div className="HamburgerMenu"></div>
                  <div className="HamburgerMenu"></div>
                  <div className="HamburgerMenu"></div>
                </div>
                <a href="/#">
                  <img className="desktop" src="/Tns-Logo.png"></img>
                  <img className="mobile" src="/logo.png"></img>
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-5 d-lg-block d-none">
            <div className="topHeader">{this.state.PageName}</div>
          </div>
          {this.state.isWrongDialedOrBundle && (
            <>
              <div
                className="col-lg-3 col-md-1 col-5 float-end p-2 pe-md-4 "
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                {this.state.user == "Normal User" && (
                  <div className="row d-lg-block">
                    <PayBillFun />
                  </div>
                )}
              </div>
              {/* <div className="col-lg-1 notificationIco">
            <img src="/svgs/bellIcon.svg"></img>{" "}
          </div> */}
              <div className="col-lg-2  col-md-2 col-6 float-end p-2 d-lg-block d-md-block d-none">
                <a className="row user-details" href="/account">
                  <div className="col-1 desktop">
                    <div className="border">
                      <hr />
                    </div>
                  </div>
                  <div className="col-3 userImage">
                    <img src={`${this.state.profilePic}`} />
                  </div>
                  <div className="col-lg-7 col-md-7 col-8">
                    {/* <div>{this.state.ClientName} <br/>{this.state.ClientEmail}</div> */}
                    <div>
                      <div className="userProfileGreet">
                        {this.state.greet}!
                      </div>
                      <div className="userName hide-text">{this.state.UserName} </div>
                    </div>
                  </div>
                </a>
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
}
export default Topbar;
