import React from "react";
import "./rentalcss.css";
import "../rentalPage_popup/rentalPage_popup.css";
import PopupModal from "../Common/Popup";
import VirtualNumber from "../rentalPage_popup/virtualNumber";

class RentalSmallCom extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};

    console.log("this.props",this.props)
  }


  renderEquipmentTypes() {
    const { NewVit } = this.props;
    if (Array.isArray(NewVit)) {
      // Render each equipment type as a separate <div> for new lines
      return NewVit.map((type, index) => (
        <div key={index}>{type}</div>
      ));
    }
    // If it's a single string, display it as is
    return this.props.equipmentType;
  }
  render() {
    return (
      <>
        <div className="RSimgBox">
          <img src={this.props.img} alt="phone" />
        </div>
        <div className="SmallFont1">{this.props.name}</div>
        <div className="SmallFont2">{this.renderEquipmentTypes()}</div>
        <div className="update">
          {this.props.popupS == "Updatepopup" ? (
            <>
              <div className={this.props.popupS}>
                <PopupModal
                  type="submit"
                  value="Submit"
                  className=""
                  class="update"
                  btnName="Add"
                  size="ExtendRentalCardSize deleteCardDismissBtn"
                >
                  <VirtualNumber
                    virNum={this.props.VirNum}
                    newVirtualNo={this.props.newVirtualNo}
                    RentalCode={this.props.RentalCode}
                  />
                </PopupModal>
              </div>
            </>
          ) : (
            ""
          )}
        </div>
      </>
    );
  }
}

export default RentalSmallCom;
