import React, { useState, useEffect, useMemo } from "react";
import "./invoice&usageCss.css";
import { removeDuplicates } from "../Helper/HelperFun";
import DataTableOrders from "../Oraganism/DataTableOrders";
import OrderStatus from "../Oraganism/orderStatus";
import $ from "jquery";
import { ApiPostCall } from "../Functions/connector";

export default function OrdersTest() {
  const [mobileFilter, setMobileFilter] = useState("filterContainerMobile");
  const [orderByEmail, setOrderByEmail] = useState([]);
  const [filterData, setFilterData] = useState({
    planName: "",
    FromDate: "",
    ToDate: "",
  });
  const [loader, setLoader] = useState(true);
  const [orderStatus, setOrderStatus] = useState(false);
  const [uniquePlanName, setUniquePlanName] = useState([]);
  const [orderStatusData, setOrderStatusData] = useState({});

  useEffect(() => {
    const loggedInUser = window.localStorage.getItem("userData");
    if (loggedInUser) {
      //   const foundUser = JSON.parse(loggedInUser);
      //   this.setState({ rentals: foundUser[0] });

      const token = window.localStorage.getItem("Token");
      var urlencoded = new URLSearchParams();
      urlencoded.append("Token", token);
      ApiPostCall("/RouterParser/OrderByEmail", urlencoded).then((result) => {
        if (result === undefined || result === "") {
          alert("Something went wrong");
        } else {
           console.log("ordertest",JSON.parse(result)[0]);

          setOrderByEmail(JSON.parse(result)[0]);
          setLoader(false);
          setUniquePlanName(
            JSON.parse(
              JSON.stringify(
                removeDuplicates(JSON.parse(result)[0], "PlanName")
              )
            )
          );

          $(".isGotData").text("1");
        }
      });
    }
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });

    setFilterData({
      planName: params.rc,
      FromDate: params.fd,
      ToDate: params.td,
    });
  }, []);

  const Filter = () => {
    var x = $("#fromCalender").val();
    var y = $("#toCalender").val();
    var m = $("#select").val();
    var n = $("#phoneSelect").val();

    window.location.href =
      "/orders?fd=" + x + "&td=" + y + "&st=" + m + "&rc=" + n;
  };

  return (
    <>
      {loader ? (
        <>
          <span className="d-none isGotData">0</span>
          <div className="loader_positon text-center">
            <img className="loader2 " src="/svgs/loader.svg" alt="eyeIcon" />
            <div className="" style={{ fontSize: "16px", fontWeight: "600" }}>
              Loading...
            </div>
          </div>
        </>
      ) : (
        !orderStatus && (
          <>
            <span className="d-none isGotData">0</span>
            <div className=" row  ">
              <div className=" Mobile">
                <div
                  className={`mobile topHeader mleft ${
                    mobileFilter == "" ? "d-none" : ""
                  } `}
                >
                  Orders
                  <i
                    className="fa fa-solid fa-filter pullRight mRight pt-2"
                    onClick={() => {
                      setMobileFilter(
                        mobileFilter == "" ? "filterContainerMobile" : ""
                      );
                    }}
                  ></i>
                </div>
              </div>
              {/* <form onSubmit={this.handleSubmit}> */}
              <div className=" col-lg-12 col-md-12 col-12">
                <div className={`row filterContainer  ${mobileFilter} `}>
                  <div className="col-10 mobile">
                    <div className="filterInvoice mobile">Filter Orders</div>
                  </div>
                  <div
                    className="col-2 closebtn mobile"
                    onClick={() => {
                      setMobileFilter(
                        mobileFilter == "" ? "filterContainerMobile" : ""
                      );
                    }}
                  >
                    x
                  </div>
                  <div className="col-lg col-md col-12 containerMargin">
                    <div className="fromDate">From</div>
                    <div className="">
                      <input
                        className="fromCalender"
                        name="begin Calender"
                        type="date"
                        id="fromCalender"
                        value={filterData.FromDate || ""}
                        onChange={(e) => {
                          setFilterData({
                            ...filterData,
                            FromDate: e.target.value,
                          });
                        }}
                      />

                      {/* <div className="text-danger">
          {this.state.errors.fromDate}
        </div> */}
                    </div>
                  </div>
                  <div className="col-lg col-md col-12">
                    <div className="toDate">To</div>
                    <div className="">
                      <input
                        className="toCalender"
                        type="date"
                        id="toCalender"
                        name="Calender"
                        value={filterData.ToDate || ""}
                        onChange={(e) => {
                          setFilterData({
                            ...filterData,
                            ToDate: e.target.value,
                          });
                        }}
                      />
                      {/* <div className="text-danger">
          {this.state.errors.toDate}
        </div> */}
                    </div>
                  </div>
                  <div className="col-lg col-md col-12">
                    <div className="statusData">Status</div>
                    <div className="">
                      <select
                        className="form-select statusbtn"
                        aria-label="Default select example"
                        id="select"
                      >
                        <option
                          className=""
                          value="-1"
                          // onChange={this.handleChange}
                        >
                          Please Select
                        </option>
                        <option value="1">Confirm</option>
                        <option value="0">Not Confirm</option>
                      </select>
                      {/* <div className="text-danger">
          {this.state.errors.status}
        </div> */}
                    </div>
                  </div>
                  <div className="col-lg col-md col-12">
                    <div className="phoneData">Type</div>
                    <div className="">
                      <select
                        className="form-select phonebtn"
                        aria-label="Default select example"
                        id="phoneSelect"
                        value={filterData.planName || ""}
                        onChange={(e) => {
                          setFilterData({
                            ...filterData,
                            planName: e.target.value,
                          });
                        }}
                      >
                        <option value="0">All</option>
                        {uniquePlanName.map((data, i) => {
                          return (
                            <>
                              <option key={i} value={data}>
                                {data}
                              </option>
                            </>
                          );
                        })}
                      </select>
                      {/* <div className="text-danger">{this.state.errors.pNum}</div> */}
                    </div>
                  </div>
                  <div className="col-lg col-md col-12">
                    <button
                      type="submit"
                      value="Submit"
                      className="btn btn applybtn"
                      onClick={Filter}
                    >
                      <div className="applyFont">Apply Filters</div>
                    </button>
                  </div>
                </div>
              </div>
              {/* </form> */}
            </div>
            <DataTableOrders
              orderByEmail={orderByEmail}
              setDynamicValues={setOrderStatusData}
              setOrderStatus={setOrderStatus}
            />
          </>
        )
      )}
      {orderStatus && (
        <OrderStatus
          orderStatusData={orderStatusData}
          setOrderStatus={setOrderStatus}
        />
      )}
    </>
  );
}
