import React, { useState, useLayoutEffect } from "react";
import { ApiPostCall } from "../Functions/connector";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FreezeSimValidation } from "../Yup Validation/YupValidations";
import $ from "jquery";
export default function FreezeSimModalNew(props) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(FreezeSimValidation),
  });
  console.log("errors",errors)
  const [FSMData, setFSMData] = useState({ rental: [], paramsRentalCode: "" });
  const [changeModal, setChangeModal] = useState(false);
  const [Success, setSuccess] = useState(false);
  const [checkboxValue, setCheckboxValue] = useState(false);
  useLayoutEffect(() => {
    const loggedInUser = window.localStorage.getItem("userData");
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });
    if (loggedInUser) {
      setFSMData({
        ...FSMData,
        rental: JSON.parse(loggedInUser)[0],
        paramsRentalCode: params.rc,
      });
    }
  }, []);


  const handleCheckboxChange = (e) => {
    // if (e.target.checked) {
      setCheckboxValue(!checkboxValue);
    // }else{
    //   setCheckboxValue(false);
    // }
  };
  const SubmitData = async (data) => {
    let lostSimRc = $($("#phoneNo").find(":selected")).attr("rentalcode");

    // console.log(data.comments, lostSimRc);
    const token = window.localStorage.getItem("Token");
    let ProviderCode = 0;
    let urlencoded = new URLSearchParams();
    urlencoded.append("RentalCode", lostSimRc);
    // urlencoded.append("RentalCode", 383857);
    urlencoded.append("Token", token);
    ProviderCode = await ApiPostCall("/RouterParser/rental ", urlencoded).then(
      (result) => {
        const responseRs = JSON.parse(result);
        return JSON.parse(responseRs.tblRentals)[0][0].RentalProviderCode;
      }
    );
    let Urlencoded = new URLSearchParams();
    Urlencoded.append("ContactNumber", data.phoneNum);
    Urlencoded.append("SimNumber", data.lostSim);
    Urlencoded.append("UserName", data.nameShippingLocation);
    Urlencoded.append("Address", data.address);
    Urlencoded.append("City", data.city);
    Urlencoded.append("Floor", data.floor);
    Urlencoded.append("Comments", data.comments);
    Urlencoded.append("Shipping", data.shippingMethod);
    Urlencoded.append("RentalCode", lostSimRc);
    Urlencoded.append("Token", token);
    Urlencoded.append("ESimCompatible", checkboxValue);
    Urlencoded.append(
      "ProviderCode",
      ProviderCode == 0 ? props.ProviderCodes : ProviderCode
    );
    ApiPostCall("/RouterParser/FreezeSim", Urlencoded).then((result) => {
      setChangeModal(true);
      // console.log("got it", result);
      if (result.split(/[\s,\?\,\.!]+/).some((f) => f === "Success")) {
        setSuccess(true);
      } else {
      console.log("freez sim issue", result);

        setSuccess(false);
      }
    });
  };
  return (
    <>
      {!changeModal ? (
        <div className="row">
          <div className="col-lg">
            <form onSubmit={handleSubmit(SubmitData)}>
              <div className=" text-center bigFont">
                Freeze and Order New Sim
              </div>
              <div className="text-center textFont">
                Fill out the form below and we will be in touch with you during
                business hours.
              </div>
              {/* <div className="RcardHeader">{this.state.SimNumber}</div> */}
              <div className="row">
                <div className="col-lg paddingLeft">
                  <div className="Font">Lost Sim</div>
                  <select
                    className="form-select selectbtn"
                    aria-label="Default select example"
                    id="phoneNo"
                    {...register("lostSim")}
                  >
                    <option value="" selected rentalcode="0">
                      Please Select
                    </option>
                    {FSMData.rental.map((rental, i) =>
                      rental.RentalCode == FSMData.paramsRentalCode ? (
                        <option
                          key={i}
                          value={rental.PhoneNumber}
                          rentalcode={rental.RentalCode}
                        >
                          {rental.PhoneNumberStr}
                        </option>
                      ) : (
                        <>
                          {rental.Status == "Active" && (
                            <option
                              key={i}
                              value={rental.PhoneNumber}
                              rentalcode={rental.RentalCode}
                            >
                              {rental.PhoneNumberStr}
                            </option>
                          )}
                        </>
                      )
                    )}
                  </select>
                  <span className="text-danger">{errors.lostSim?.message}</span>
                </div>
              </div>
              <div className="row">
                <div className="col-lg paddingLeft">
                  <div className="Font">Name at Shipping Location</div>
                  <input
                    id="input"
                    className="box"
                    type="text"
                    name="name"
                    placeholder="Full Name"
                    {...register("nameShippingLocation")}
                  />
                  <span className="text-danger">
                    {errors.nameShippingLocation?.message}
                  </span>
                </div>
              </div>
              <div className="row">
                <div className="col-lg paddingLeft">
                  {/* <div className="Font">Contact Number in Israel</div> */}
                  <div className="Font">Israeli Contact Number</div>
                  <input
                    id="contactno"
                    className="box"
                    type="text"
                    name="phone"
                    placeholder="Phone Number"
                    {...register("phoneNum")}
                  />
                  <span className="text-danger">
                    {errors.phoneNum?.message
                      .split(/[\s,\?\,\.!]+/)
                      .some((f) => f === "`number`")
                      ? "Please enter only digits."
                      : errors.phoneNum?.message}
                  </span>
                </div>
              </div>
              <div className="row">
                <div className="col-lg paddingLeft">
                  <div className="Font">Street Name & Number</div>
                  <input
                    className="box"
                    type="text"
                    name="address"
                    placeholder="Street Name & Number"
                    {...register("address")}
                  />
                  <span className="text-danger">{errors.address?.message}</span>
                </div>
              </div>
              <div className="row">
                <div className="col-lg paddingLeft">
                  <div className="Font">Entrance/Floor/Apt</div>
                  <input
                    className="box"
                    type="text"
                    name="floor"
                    placeholder="Entrance/Floor/Apt"
                    {...register("floor")}
                  />
                  <span className="text-danger">{errors.floor?.message}</span>
                </div>
                <div className="col-lg paddingLeft">
                  <div className="Font">City</div>
                  <input
                    className="box"
                    type="text"
                    name="city"
                    placeholder="City"
                    {...register("city")}
                  />
                  <span className="text-danger">{errors.city?.message}</span>
                </div>
              </div>
              <div className="row">
                <div className="col-lg paddingLeft">
                  <div className="Font">Shipping Method</div>
                  <select
                    className="form-select selectbtn"
                    aria-label="Default select example"
                    id="shippingMethod"
                    {...register("shippingMethod")}
                  >
                    <option defaultValue className="PlaceHolder" value="">
                      Please Select
                    </option>
                    <option value="Jerusalem - $10 +VAT">
                      Jerusalem - $10 +VAT
                    </option>
                    <option value="Outside Jerusalem - $15 +VAT">
                      Outside Jerusalem - $15 +VAT
                    </option>
                  </select>
                  <span className="text-danger">
                    {errors.shippingMethod?.message}
                  </span>
                </div>
              </div>
              <div className="row">
                <div className="col-lg">
                <input type="checkbox" id="esim_compatible" onChange={handleCheckboxChange} checked={checkboxValue}  />
                &nbsp;&nbsp;My phone is eSIM compatible
                </div>
              </div>
              <div className="row">
                <div className="col-lg paddingLeft">
                  <div className="Font">Comments</div>
                  <textarea
                    className="box comments"
                    type="text"
                    name="comments"
                    {...register("comments")}
                  />
                </div>
              </div>

              <div className="row">
                <div className=" mt-2 w-100 p-0 infoSmallText">
                  By clicking this button, your line will be frozen and you will
                  be charged $10 +VAT for the Sim replacement plus the shipping
                  cost you chose.
                </div>
                <div className="col-lg paddingLeft">
                  <button
                    type="submit"
                    value="Submit"
                    className="btn btn applybtn1"
                  >
                    <div className="applyFont">
                      Freeze Current Sim & Order New Sim
                    </div>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      ) : Success ? (
        <>
          <div className="details">
            <div className="text-center alldetailsFont">Sim Card Frozen!</div>
            <div className="text-center textFont">
              Your request has been sent, you will receive an Email for further
              information !
            </div>
          </div>
          <div className="row">
            {/* <div className="col-lg">
            <button
              type="submit"
              className="btn btn applybtn2"
              onClick={this.close}
            >
              <div className="applyFont">Close</div>
            </button>
          </div> */}
          </div>
        </>
      ) : (
        <>
          <div className="details">
            <div className="text-center alldetailsFont">
              Sim Card Not Freezed!
            </div>
            <div className="text-center textFont">Contact to Service!</div>
          </div>
          <div className="row">
            {/* <div className="col-lg">
            <button
              type="submit"
              className="btn btn applybtn2"
              onClick={this.close}
            >
              <div className="applyFont">Close</div>
            </button>
          </div> */}
          </div>
        </>
      )}
    </>
  );
}
