import React, { useState } from "react";
import { useParams } from "react-router-dom";
import formlogo from '../../form-logo.png';
import eyesShow from '../../eye-show.png';
import eyeshide from '../../eyes-hide.png';
import $ from "jquery";
import { ApiPostCall } from "../Functions/connector";
export default function ResetPassword() {
  const { email } = useParams(); // Extract email from route params

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const [passwordError, setPasswordError] = useState("");

  const toggleVisibility = (setter) => {
    setter((prevState) => !prevState);
  };

  const validatePassword = (value) => {
    const strongPasswordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$%^&+=!]).{8,}$/;

    if (!strongPasswordRegex.test(value)) {
      setPasswordError(
        "Password must be at least 8 characters long and include uppercase, lowercase, a number, and a special character."
      );
    } else {
      setPasswordError("");
    }
  };

  const handlePasswordChange = (e) => {
    const value = e.target.value;
    setPassword(value);
    validatePassword(value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handleSubmit = () => {
    if (password && password === confirmPassword && !passwordError) {
      // Call API with email and password
      console.log("Email:", email); // Use this email for API request
      console.log("Password:", password);
      var urlencoded = new URLSearchParams();
      urlencoded.append("NewPassword", password);
      urlencoded.append("UserEmail", email);
      ApiPostCall("/Profile/resetPassword", urlencoded).then((result) => {
        // console.log("got it", result);
        var results = result;
        // console.log(results);
        if (results == '"IncorrectEmail"') {
        console.log("incorrect Email");
  
          $(".successMsg").text("incorrect Email.");
        }
  
        if (results == '"success"') {
          console.log("correct password");
          window.location.href = "/login";
          $(".successMsg").text("Your password has been changed successfully!");     
        }
      });
    }
  };

  return (
    <React.Fragment>
      <div className="row m-0 loginPageBody">
        <div className="card col-lg-4 m-auto border-0">
          <div className="row p-1">
            <div className="col-12 d-flex justify-content-center align-self-center">
              <img src="/Tns-Logo.png" alt="Logo" />
            </div>
            <div className="col-12">
              <div className="card mt-4 border-0 authCard p-4">
                <div className="text-center">
                  <img src={formlogo} width={50} alt="Form Logo" />
                </div>
                <div className="align-self-center pb-3 pt-3 text-center rsetpasswod">
                  <h4 className="themeTxtColor">Welcome to WebSelfCare!</h4>
                  <p>
                    We've automatically set up your account to make managing
                    your TalknSave services easier than ever. To get started,
                    simply create a password for quick and easy access anytime.
                  </p>
                  {/* <p className="text-primary">
                    Resetting password for: <strong>{email}</strong>
                  </p> */}
                </div>
                <div className="form-group addpassrd">
                  <label htmlFor="password">Password</label>
                  <div className="input-group addpassrd">
                    <input
                      type={passwordVisible ? "text" : "password"}
                      className="form-control"
                      id="password"
                      placeholder="Enter Password"
                      value={password}
                      onChange={handlePasswordChange}
                    />
                    <span
                      className="input-paswodicon"
                      onClick={() => toggleVisibility(setPasswordVisible)}
                      style={{ cursor: "pointer" }}
                    >
                      <img
                        src={`${passwordVisible ? eyesShow : eyeshide}`}
                        alt="toggle visibility"
                      />
                    </span>
                  </div>
                  {passwordError && (
                    <p className="text-danger mt-1">{passwordError}</p>
                  )}
                </div>

                <div className="form-group addpassrd">
                  <label htmlFor="confirmPassword">Confirm Password</label>
                  <div className="input-group">
                    <input
                      type={confirmPasswordVisible ? "text" : "password"}
                      className="form-control"
                      id="confirmPassword"
                      placeholder="Confirm Password"
                      value={confirmPassword}
                      onChange={handleConfirmPasswordChange}
                    />
                    <span
                      className="input-paswodicon"
                      onClick={() => toggleVisibility(setConfirmPasswordVisible)}
                      style={{ cursor: "pointer" }}
                    >
                      <img
                        src={`${confirmPasswordVisible ? eyesShow : eyeshide}`}
                        alt="toggle visibility"
                      />
                    </span>
                  </div>
                  {password && confirmPassword && password !== confirmPassword && (
                    <p className="text-danger mt-1">Passwords do not match.</p>
                  )}
                </div>

                <button
                  type="button"
                  className="btn btn-primary btn-lg btn-block waves-effect waves-light mt-3 themeTxtColor"
                  disabled={
                    passwordError ||
                    !password ||
                    !confirmPassword ||
                    password !== confirmPassword
                  }
                  onClick={handleSubmit}
                >
                  Create Password
                </button>

                <div className="passwordreue">
                  <p>Password Requirements:</p>
                  <ul>
                    <li>At least 8 characters</li>
                    <li>One uppercase letter</li>
                    <li>One lowercase letter</li>
                    <li>One number</li>
                    <li>One special character (e.g., @, #, $)</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
